/* index.module.css */
.HEADER {
  @apply bg-yellow-500 text-black p-4 sticky top-0 z-10;
}

.CONTAINER {
  @apply container mx-auto flex justify-between items-center;
}

.LOGO {
  @apply text-2xl font-bold;
}

.LOGOLINK {
  @apply text-black hover:text-yellow-700;
}

.LOGOIMAGE {
  @apply h-[70px];
}

.NAVLIST {
  @apply flex space-x-6;
}

.NAVLINK {
  @apply hover:text-yellow-700 p-1;
}

.NAVLINK_ACTIVE {
  @apply text-yellow-400 bg-black p-1;
}

.LOGOUTLINK {
  @apply hover:text-yellow-700 cursor-pointer;
}
