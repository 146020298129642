/* index.module.css */
.CONTAINER {
  @apply max-w-4xl mx-auto py-8;
}

.HEADER {
  @apply text-3xl font-bold mb-4 text-center;
}

.BLOG_LIST {
  @apply grid grid-cols-1 gap-4;
}

.BLOG_ITEM {
  @apply p-4 border border-gray-200 rounded-md cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out;
}

.SELECTED {
  @apply bg-yellow-100 border-yellow-500;
}

.COVER_IMAGE {
  @apply w-full h-48 object-cover rounded-md mb-4;
}

.BLOG_DETAILS {
  @apply flex flex-col;
}

.TITLE {
  @apply text-xl font-semibold;
}

.CREATED_BY,
.CREATED_AT {
  @apply text-gray-600 text-sm;
}

/* Buttons */
.SELECT_ALL_BUTTON {
  @apply bg-blue-500 text-white px-4 py-2 rounded mb-4 hover:bg-blue-600 transition-colors duration-200 ease-in-out;
}

.UPDATE_BUTTON {
  @apply bg-green-500 text-white px-4 py-2 rounded mt-2 hover:bg-green-600 transition-colors duration-200 ease-in-out;
}

.DELETE_BUTTON {
  @apply bg-red-500 text-white px-4 py-2 rounded mt-4 w-full hover:bg-red-600 transition-colors duration-200 ease-in-out;
}
